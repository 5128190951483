import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import auth from "../../services/auth";
import Combined from "../../services/combinedAuth";
import business from "../../services/business";
import dashboard from "../../services/dasboard";
import orders from "../../services/orders";
import products from "../../services/products";
import blogs from "../../services/blogs";
import { socketServer } from "../../realtimeCommunication/socketConnection";
import { clearCookie } from "../../helpers/common";

const rootReducer = combineReducers({
  home: () => null,
  auth,
  dashboard,
  orders,
  business,
  products,
  blogs,
});

const AppReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    storage.removeItem("persist:root");
    storage.removeItem("persist:DATA_PERSISTANT_KEY");
    let sockets = state?.auth?.sockets;

    if (socketServer()) {
      socketServer().emit("forceDisconnect", sockets);
      socketServer().close();
      socketServer().off();
    }
    localStorage.removeItem("token");
    clearCookie("allowed_pages");
    clearCookie("sessionId");

    state = undefined;
  }

  return rootReducer(state, action);
};

export default AppReducer;
