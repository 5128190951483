import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import MedicationIcon from "@mui/icons-material/Medication";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PersonIcon from "@mui/icons-material/Person";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import Person3Icon from "@mui/icons-material/Person3";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import PaidIcon from "@mui/icons-material/Paid";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";

const sidnav = [
  {
    name: "Dashboard",
    file: "dashboard",
    navigations: true,
    menubar: true,
    live: true,
    buttons: [],
    icon: <DashboardIcon style={{ color: "white" }} />,
    link_to: "/dash/dashboard",
    link_for: null,
  },

  {
    name: "Business",
    file: "business",
    navigations: true,
    menubar: true,
    live: true,
    buttons: [],
    icon: <BusinessCenterIcon style={{ color: "white" }} />,
    link_to: "/dash/business",
    link_for: null,
  },
  {
    name: "Stores",
    file: "stores",
    navigations: true,
    menubar: true,
    live: true,
    buttons: [],
    icon: <MedicationIcon style={{ color: "white" }} />,
    link_to: "/dash/stores",
    link_for: null,
  },
  {
    name: "Orders",
    file: "orders",
    navigations: true,
    menubar: true,
    live: true,
    buttons: [],
    icon: <BorderColorIcon style={{ color: "white" }} />,
    link_to: "/dash/orders",
    link_for: "order-detail",
  },

  {
    name: "Orders Detail",
    file: "order-detail",
    navigations: true,
    menubar: false,
    live: true,
    buttons: [],
    icon: <BorderColorIcon style={{ color: "white", fontSize: "26px" }} />,
    link_to: "/dash/order-detail/:id",
    link_for: null,
  },
  {
    name: "Products",
    file: "products",
    navigations: true,
    menubar: true,
    live: true,
    buttons: [],
    icon: <Inventory2Icon style={{ color: "white" }} />,
    link_to: "/dash/products",
    link_for: "product",
  },
  {
    name: "Products Detail",
    file: "product-detail",
    navigations: true,
    menubar: false,
    live: true,
    buttons: [],
    icon: <Inventory2Icon style={{ color: "white" }} />,
    link_to: "/dash/product-detail/:id",
    link_for: null,
  },
  {
    name: "Queries",
    file: "queries",
    navigations: true,
    menubar: true,
    live: true,
    buttons: [],
    icon: <DashboardIcon style={{ color: "white" }} />,
    link_to: "/dash/queries",
    link_for: null,
  },

  {
    name: "Business Members",
    file: "members",
    navigations: true,
    menubar: true,
    live: true,
    buttons: [],
    icon: <PersonIcon style={{ color: "white" }} />,
    link_to: "/dash/members",
    link_for: null,
  },

  // {
  //   name: "Blogs",
  //   file: "blogs",
  //   navigations: true,
  //   menubar: true,
  //   live: true,
  //   buttons: [],
  //   icon: <NewspaperIcon style={{ color: "white" }} />,
  //   link_to: "/dash/blogs",
  //   link_for: "blog",
  // },
  // {
  //   name: "Blogs Detail",
  //   file: "blog-detail",
  //   navigations: true,
  //   menubar: false,
  //   live: true,
  //   buttons: [],
  //   icon: <NewspaperIcon style={{ color: "white" }} />,
  //   link_to: "/dash/blog-detail/:id",
  //   link_for: null,
  // },
  // {
  //   name: "Add or Edit Blog",
  //   file: "blog",
  //   navigations: true,
  //   menubar: false,
  //   live: true,
  //   buttons: [],
  //   icon: <NewspaperIcon style={{ color: "white" }} />,
  //   link_to: "/dash/blog/:id",
  //   link_for: null,
  // },
  // {
  //   name: "Permissions ",
  //   file: "permissions",
  //   navigations: true,
  //   menubar: true,
  //   live: true,
  //   buttons: [],
  //   icon: <PeopleOutlineIcon style={{ color: "white" }} />,
  //   link_to: "/dash/permissions",
  //   link_for: null,
  // },
  // {
  //   name: "Users ",
  //   file: "users",
  //   navigations: true,
  //   menubar: true,
  //   live: true,
  //   buttons: [],
  //   icon: <Person3Icon style={{ color: "white" }} />,
  //   link_to: "/dash/users",
  //   link_for: null,
  // },
  // {
  //   name: "No Permissions",
  //   file: "no-permissions",
  //   navigations: true,
  //   menubar: false,
  //   live: true,
  //   buttons: [],
  //   icon: "/dash/no-permissions",
  //   link_for: null,
  // },
  // {
  //   name: "Manage Drivers",
  //   file: "manage-drivers",
  //   navigations: true,
  //   menubar: true,
  //   live: true,
  //   buttons: [],
  //   icon: <NewspaperIcon style={{ color: "white" }} />,
  //   link_to: "",
  //   link_for: null,
  //   children: [
  //     {
  //       name: "Drivers",
  //       file: "drivers",
  //       navigations: true,
  //       menubar: true,
  //       live: true,
  //       buttons: [],
  //       icon: <DirectionsBikeIcon style={{ color: "white" }} />,
  //       link_to: "/dash/drivers",
  //       link_for: null,
  //     },
  //     {
  //       name: "State Taxes",
  //       file: "state-taxes",
  //       navigations: true,
  //       menubar: true,
  //       live: true,
  //       buttons: [],
  //       icon: <PaidIcon style={{ color: "white" }} />,
  //       link_to: "/dash/state-taxes",
  //       link_for: null,
  //     },

  //     {
  //       name: "City Prices",
  //       file: "city-prices",
  //       navigations: true,
  //       menubar: true,
  //       live: true,
  //       buttons: [],
  //       icon: <CurrencyExchangeIcon style={{ color: "white" }} />,
  //       link_to: "/dash/city-prices",
  //       link_for: null,
  //     },
  //   ],
  // },
  // {
  //   name: "System Configs",
  //   file: "system-config",
  //   navigations: true,
  //   menubar: true,
  //   live: true,
  //   buttons: [],
  //   icon: <DashboardIcon style={{ color: "white" }} />,
  //   link_to: "",
  //   link_for: null,
  //   children: [
  //     {
  //       name: "System Parameters ",
  //       file: "parameters",
  //       navigations: true,
  //       menubar: true,
  //       live: true,
  //       buttons: [],
  //       icon: <StickyNote2Icon style={{ color: "white" }} />,
  //       link_to: "/dash/parameters",
  //       link_for: null,
  //     },
  //   ],
  // },
];
export default sidnav;
