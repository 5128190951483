import { createAction, handleActions } from "redux-actions";
import {
  _getAdminOrdersList,
  _getOrderDetail,
  _updateOrderStatus,
  _generateOrderQR,
  _getBlogsList,
  _getBlogsDetail,
  _updateBlog,
  _addBlog,
  _deleteBlog,
} from "../../shared/httpService/api";
import ActionTypes from "../../shared/constants/actionTypes";
import { toast } from "react-toastify";

const initialState = {
  blogs: { loading: false, response: {}, hasError: false, error: {} },
  blogDetail: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  updateBlog: { loading: false },
  addBlog: { loading: false, response: {}, hasError: false, error: {} },
  deleteBlog: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
};

export const getBlogsStart = createAction(ActionTypes.GET_BLOGS_START);
export const getBlogsSuccess = createAction(
  ActionTypes.GET_BLOGS_SUCCESS,
  (response) => response
);
export const getBlogsError = createAction(
  ActionTypes.GET_BLOGS_ERROR,
  (error) => error
);

export const getBlogsList = (page, limit, callback) => (dispatch) => {
  dispatch(getBlogsStart());
  return _getBlogsList(page, limit)
    .then((response) => {
      dispatch(getBlogsSuccess(response));
      if (response) {
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(getBlogsError(error));

      if (error && error?.error) {
        toast.error(error?.message);
      } else if (error && error.message) {
        toast.error(error?.message);
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const getBlogsDetailStart = createAction(
  ActionTypes.GET_BLOGS_DETAIL_START
);
export const getBlogsDetailSuccess = createAction(
  ActionTypes.GET_BLOGS_DETAIL_SUCCESS,
  (response) => response
);
export const getBlogsDetailError = createAction(
  ActionTypes.GET_BLOGS_DETAIL_ERROR,
  (error) => error
);

export const getBlogsDetail = (id, callback) => async (dispatch) => {
  try {
    dispatch(getBlogsDetailStart());

    const response = await _getBlogsDetail(id);
    if (response) {
      callback(response);
    }
    dispatch(getBlogsDetailSuccess(response));
  } catch (error) {
    dispatch(getBlogsDetailError(error));
    if (error?.status.length > 0) {
      toast.error(error?.message);
    } else if (error && error?.error) {
      toast.error(error?.message);
    } else if (error && error.message) {
      toast.error(error?.message);
    } else {
      toast.error("Something went wrong");
    }
  }
};

export const updateBlogStart = createAction(ActionTypes.UPDATE_BLOG_START);
export const updateBlogSuccess = createAction(
  ActionTypes.UPDATE_BLOG_SUCCESS,
  (response) => response
);
export const updateBlogError = createAction(
  ActionTypes.UPDATE_BLOG_ERROR,
  (error) => error
);
export const updateBlog = (id, data, callback) => async (dispatch) => {
  try {
    dispatch(updateBlogStart());

    const response = await _updateBlog(id, data);
    if (response) {
      callback(response);
    }
    dispatch(updateBlogSuccess(response));
  } catch (error) {
    dispatch(updateBlogError(error));
    if (error?.status.length > 0) {
      toast.error(error?.message);
    } else if (error && error?.error) {
      toast.error(error?.message);
    } else if (error && error.message) {
      toast.error(error?.message);
    } else {
      toast.error("Something went wrong");
    }
  }
};

export const addBlogStart = createAction(ActionTypes.ADD_BLOG_START);
export const addBlogSuccess = createAction(
  ActionTypes.ADD_BLOG_SUCCESS,
  (response) => response
);
export const addBlogError = createAction(
  ActionTypes.ADD_BLOG_ERROR,
  (error) => error
);
export const addBlog = (data, callback) => async (dispatch) => {
  try {
    dispatch(addBlogStart());

    const response = await _addBlog(data);
    if (response) {
      callback(response);
    }
    dispatch(addBlogSuccess(response));
  } catch (error) {
    dispatch(addBlogError(error));
    if (error?.status.length > 0) {
      toast.error(error?.message);
    } else if (error && error?.error) {
      toast.error(error?.message);
    } else if (error && error.message) {
      toast.error(error?.message);
    } else {
      toast.error("Something went wrong");
    }
  }
};

export const deleteBlogStart = createAction(ActionTypes.DELETE_BLOG_START);
export const deleteBlogSuccess = createAction(
  ActionTypes.DELETE_BLOG_SUCCESS,
  (response) => response
);
export const deleteBlogError = createAction(
  ActionTypes.DELETE_BLOG_ERROR,
  (error) => error
);
export const deleteBlog = (id, callback) => async (dispatch) => {
  try {
    dispatch(deleteBlogStart());

    const response = await _deleteBlog(id);
    if (response) {
      callback(response);
    }
    dispatch(deleteBlogSuccess(response));
    toast.success(response.message);
  } catch (error) {
    dispatch(deleteBlogError(error));
    if (error?.status.length > 0) {
      toast.error(error?.message);
    } else if (error && error?.error) {
      toast.error(error?.message);
    } else if (error && error.message) {
      toast.error(error?.message);
    } else {
      toast.error("Something went wrong");
    }
  }
};
const reducer = handleActions(
  {
    [ActionTypes.GET_BLOGS_START]: (state) => ({
      ...state,
      blogs: {
        ...state.blogs,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_BLOGS_SUCCESS]: (state, action) => ({
      ...state,
      blogs: {
        ...state.blogs,
        loading: false,
        hasError: false,
        error: {},
        response: action.payload?.data,
      },
    }),
    [ActionTypes.GET_BLOGS_ERROR]: (state) => ({
      ...state,
      blogs: {
        ...state.blogs,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),

    [ActionTypes.GET_BLOGS_DETAIL_START]: (state) => ({
      ...state,
      blogDetail: {
        loading: true,
        response: {},
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_BLOGS_DETAIL_SUCCESS]: (state, action) => ({
      ...state,
      blogDetail: {
        ...state.blogDetail,
        response: action.payload.data,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_BLOGS_DETAIL_ERROR]: (state, action) => ({
      ...state,
      blogDetail: {
        ...state.blogDetail,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),

    [ActionTypes.UPDATE_BLOG_START]: (state) => ({
      ...state,
      updateBlog: {
        loading: true,
      },
    }),
    [ActionTypes.UPDATE_BLOG_SUCCESS]: (state, action) => ({
      ...state,
      updateBlog: {
        loading: false,
      },
    }),
    [ActionTypes.UPDATE_BLOG_ERROR]: (state, action) => ({
      ...state,
      updateBlog: {
        loading: false,
      },
    }),

    [ActionTypes.ADD_BLOG_START]: (state) => ({
      ...state,
      addBlog: {
        ...state.addBlog,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.ADD_BLOG_SUCCESS]: (state, action) => ({
      ...state,
      addBlog: {
        ...state.addBlog,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),
    [ActionTypes.ADD_BLOG_ERROR]: (state, action) => ({
      ...state,
      addBlog: {
        ...state.addBlog,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),

    [ActionTypes.DELETE_BLOG_START]: (state) => ({
      ...state,
      deleteBlog: {
        loading: true,
        response: {},
        hasError: false,
        error: {},
      },
    }),

    [ActionTypes.DELETE_BLOG_SUCCESS]: (state, action) => ({
      ...state,
      deleteBlog: {
        ...state.deleteBlog,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.DELETE_BLOG_ERROR]: (state, action) => ({
      ...state,
      deleteBlog: {
        ...state.deleteBlog,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),
  },
  initialState
);

export default reducer;
