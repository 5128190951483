import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthContext } from "../context/authContext";
import ForgetPassword from "../pages/forgetPassword";
import ResetPassword from "../pages/resetPassword";
import Signin from "../pages/Signin";

import Pages from "../pages";

function NotFoundRoute({ isLoggedIn, redirectTo }) {
  return isLoggedIn ? (
    <Navigate to={redirectTo} />
  ) : (
    <Navigate to={redirectTo} />
  );
}

function PublicRoute({ isLoggedIn, redirectTo }) {
  return isLoggedIn ? <Navigate to={redirectTo} /> : <Outlet />;
}

function PrivateRoute({ isLoggedIn, redirectTo }) {
  return isLoggedIn ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to={redirectTo} />
  );
}

const Router = () => {
  const { isLoggedIn, allowedPages } = useContext(AuthContext);
  const { user, authenticated } = useSelector((state) => state.auth);
  return (
    <Routes>
      <Route
        path="/dash/:view"
        element={
          <PrivateRoute
            redirectTo="/login"
            isLoggedIn={user && user?.token && user?.email ? true : false}
          />
        }
      >
        <Route path="/dash/:view" element={<Pages />} />
      </Route>

      <Route
        path="/login"
        element={
          <PublicRoute
            isLoggedIn={user?.token && user?.email ? true : false}
            redirectTo={"/dash/dashboard"}
          />
        }
      >
        <Route path="/login" element={<Signin />} />
      </Route>

      <Route
        path="/"
        element={
          <PublicRoute
            isLoggedIn={user?.token && user?.email ? true : false}
            redirectTo={"/dash/dashboard"}
          />
        }
      >
        <Route path="/" element={<Signin />} />
      </Route>

      <Route
        path="/forgotPassword"
        element={
          <PublicRoute
            isLoggedIn={user?.token && user?.email ? true : false}
            redirectTo={"/dash/dashboard"}
          />
        }
      >
        <Route path="/forgotPassword" element={<ForgetPassword />} />
      </Route>

      <Route
        exact
        path="/resetPassword/:uniqueString"
        element={<ResetPassword />}
      />

      <Route
        path="*"
        element={
          <NotFoundRoute
            isLoggedIn={isLoggedIn}
            redirectTo={
              allowedPages && allowedPages?.length > 0 ? `/dash/dashboard` : "/"
            }
          />
        }
      />
    </Routes>
  );
};

export default Router;
