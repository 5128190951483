import { createAction, handleActions } from "redux-actions";
import {
  _getAdminPharmaciesList,
  _getAdminPharmacy,
  _updateAdminPharmacyStatus,
  _sendReUploadLink,
  _orderReporting,
  _monthlySaleReport,
  _topSellingProducts,
  _getOrderCardReporting,
  _getAdminQueriesList,
  _getAdminQueries,
  _getParametersList,
  _getCityPricesList,
  _getStateTaxesList,
  _getDriversList,
} from "../../shared/httpService/api";
import ActionTypes from "../../shared/constants/actionTypes";
import { toast } from "react-toastify";

const initialState = {
  pharmacies: { loading: false, response: {}, hasError: false, error: {} },
  queries: { loading: false, response: {}, hasError: false, error: {} },
  parameters: { loading: false, response: {}, hasError: false, error: {} },
  stateTaxes: { loading: false, response: {}, hasError: false, error: {} },
  drivers: { loading: false, response: {}, hasError: false, error: {} },
  cityPrices: { loading: false, response: {}, hasError: false, error: {} },
  pharmacy: { loading: false, response: {}, hasError: false, error: {} },
  queriesDetail: { loading: false, response: {}, hasError: false, error: {} },
  updatePharmacyStatus: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  reUploadDocs: { loading: false },
  pharmacyId: "",
  orderReporting: { loading: false, response: {}, hasError: false, error: {} },
  monthlySaleReport: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
  orderCardsReporting: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },

  topSellingProducts: {
    loading: false,
    response: {},
    hasError: false,
    error: {},
  },
};

export const getPharmaciesAdminStart = createAction(
  ActionTypes.GET_ADMIN_PHARMACIES
);
export const getPharmaciesAdminSuccess = createAction(
  ActionTypes.GET_ADMIN_PHARMACIES_SUCCESS,
  (response) => response
);
export const getPharmaciesAdminError = createAction(
  ActionTypes.GET_ADMIN_PHARMACIES_ERROR,
  (error) => error
);

export const getAdminPharmaciesList =
  (search, status, page, limit, callback) => (dispatch) => {
    dispatch(getPharmaciesAdminStart());
    return _getAdminPharmaciesList(search, status, page, limit)
      .then((response) => {
        dispatch(getPharmaciesAdminSuccess(response));
        if (response) {
          callback(response);
        }
      })
      .catch((error) => {
        dispatch(getPharmaciesAdminError(error));
        if (error && error?.error) {
          toast.error(error?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

export const getQueriesAdminStart = createAction(ActionTypes.GET_ADMIN_QUERIES);
export const getQueriesAdminSuccess = createAction(
  ActionTypes.GET_ADMIN_QUERIES_SUCCESS,
  (response) => response
);
export const getQueriesAdminError = createAction(
  ActionTypes.GET_ADMIN_QUERIES_ERROR,
  (error) => error
);

export const getAdminQueriesList =
  (search, status, page, limit, callback) => (dispatch) => {
    dispatch(getQueriesAdminStart());
    return _getAdminQueriesList(search, status, page, limit)
      .then((response) => {
        dispatch(getQueriesAdminSuccess(response));
        if (response) {
          callback(response);
        }
      })
      .catch((error) => {
        dispatch(getQueriesAdminError(error));
        if (error && error?.error) {
          toast.error(error?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

export const getParametersStart = createAction(
  ActionTypes.GET_PARAMETERS_START
);
export const getParametersSuccess = createAction(
  ActionTypes.GET_PARAMETERS_SUCCESS,
  (response) => response
);
export const getParametersError = createAction(
  ActionTypes.GET_PARAMETERS_ERROR,
  (error) => error
);

export const getParametersList = (page, limit, callback) => (dispatch) => {
  dispatch(getParametersStart());
  return _getParametersList(page, limit)
    .then((response) => {
      dispatch(getParametersSuccess(response));
      if (response) {
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(getParametersError(error));
      if (error && error?.error) {
        toast.error(error?.message);
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const getDriversStart = createAction(ActionTypes.GET_DRIVERS_START);
export const getDriversSuccess = createAction(
  ActionTypes.GET_DRIVERS_SUCCESS,
  (response) => response
);
export const getDriversError = createAction(
  ActionTypes.GET_DRIVERS_ERROR,
  (error) => error
);

export const getDriversList = (page, limit, callback) => (dispatch) => {
  dispatch(getDriversStart());
  return _getDriversList(page, limit)
    .then((response) => {
      dispatch(getDriversSuccess(response));
      if (response) {
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(getDriversError(error));
      if (error && error?.error) {
        toast.error(error?.message);
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const getStateTaxesStart = createAction(
  ActionTypes.GET_STATE_TAXES_START
);
export const getStateTaxesSuccess = createAction(
  ActionTypes.GET_STATE_TAXES_SUCCESS,
  (response) => response
);
export const getStateTaxesError = createAction(
  ActionTypes.GET_STATE_TAXES_ERROR,
  (error) => error
);

export const getStateTaxesList = (page, limit, callback) => (dispatch) => {
  dispatch(getStateTaxesStart());
  return _getStateTaxesList(page, limit)
    .then((response) => {
      dispatch(getStateTaxesSuccess(response));
      if (response) {
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(getStateTaxesError(error));
      if (error && error?.error) {
        toast.error(error?.message);
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const getCityPricesStart = createAction(
  ActionTypes.GET_CITY_PRICES_START
);
export const getCityPricesSuccess = createAction(
  ActionTypes.GET_CITY_PRICES_SUCCESS,
  (response) => response
);
export const getCityPricesError = createAction(
  ActionTypes.GET_CITY_PRICES_ERROR,
  (error) => error
);

export const getCityPricesList = (page, limit, callback) => (dispatch) => {
  dispatch(getCityPricesStart());
  return _getCityPricesList(page, limit)
    .then((response) => {
      dispatch(getCityPricesSuccess(response));
      if (response) {
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(getCityPricesError(error));
      if (error && error?.error) {
        toast.error(error?.message);
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const getPharmacyAdminStart = createAction(
  ActionTypes.GET_ADMIN_PHARMACY
);
export const getPharmacyAdminSuccess = createAction(
  ActionTypes.GET_ADMIN_PHARMACY_SUCCESS,
  (response) => response
);
export const getPharmacyAdminError = createAction(
  ActionTypes.GET_ADMIN_PHARMACY_ERROR,
  (error) => error
);

export const getAdminPharmacy = (id) => (dispatch) => {
  dispatch(getPharmacyAdminStart());
  return _getAdminPharmacy(id)
    .then((response) => {
      dispatch(getPharmacyAdminSuccess(response));
    })
    .catch((error) => {
      dispatch(getPharmacyAdminError(error));
      if (error && error?.error) {
        toast.error(error?.message);
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const getQueriesDetailAdminStart = createAction(
  ActionTypes.GET_ADMIN_QUERIES_DETAIL
);
export const getQueriesDetailAdminSuccess = createAction(
  ActionTypes.GET_ADMIN_QUERIES_DETAIL_SUCCESS,
  (response) => response
);
export const getQueriesDetailAdminError = createAction(
  ActionTypes.GET_ADMIN_QUERIES_DETAIL_ERROR,
  (error) => error
);

export const getAdminQueriesDetail = (id) => (dispatch) => {
  dispatch(getQueriesDetailAdminStart());
  return _getAdminQueries(id)
    .then((response) => {
      dispatch(getQueriesDetailAdminSuccess(response));
    })
    .catch((error) => {
      dispatch(getQueriesDetailAdminError(error));
      if (error && error?.error) {
        toast.error(error?.message);
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const updatePharmaciesAdminStart = createAction(
  ActionTypes.UPDATE_PHARMACY_STATUS_ADMIN_PHARMACY
);
export const updatePharmaciesAdminSuccess = createAction(
  ActionTypes.UPDATE_PHARMACY_STATUS_SUCCESS,
  (response) => response
);
export const updatePharmaciesAdminError = createAction(
  ActionTypes.UPDATE_PHARMACY_STATUS_ERROR,
  (error) => error
);

export const updateAdminPharmacyStatus = (data, id, callback) => (dispatch) => {
  dispatch(updatePharmaciesAdminStart());
  return _updateAdminPharmacyStatus(data, id)
    .then((response) => {
      dispatch(updatePharmaciesAdminSuccess(response));
      if (response) {
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(updatePharmaciesAdminError(error));
      if (error && error?.error) {
        toast.error(error?.message);
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const docReuploadLinkdminStart = createAction(
  ActionTypes.SEND_REUPLOAD_LINK_START
);
export const docReuploadLinkAdminSuccess = createAction(
  ActionTypes.SEND_REUPLOAD_LINK_SUCCESS,
  (response) => response
);
export const docReuploadLinkAdminError = createAction(
  ActionTypes.SEND_REUPLOAD_LINK_ERROR,
  (error) => error
);

export const sendReUploadLink = (id) => (dispatch) => {
  dispatch(docReuploadLinkdminStart());
  return _sendReUploadLink(id)
    .then((response) => {
      dispatch(docReuploadLinkAdminSuccess(response));
      if (response) {
        toast.success(`${response?.message}`);
      }
    })
    .catch((error) => {
      dispatch(docReuploadLinkAdminError(error));
      if (error && error?.error) {
        toast.error(error?.message);
      } else {
        toast.error("Something went wrong");
      }
    });
};

export const pharmacyIdSuccess = createAction("SET_PHARMACY_ID");

export const setPharmacyID = (id) => (dispatch) => {
  dispatch(pharmacyIdSuccess(id));
};

export const getOrderCardReportingStart = createAction(
  ActionTypes.GET_ORDER_CARDS_REPORTING_START
);
export const getOrderCardReportingSuccess = createAction(
  ActionTypes.GET_ORDER_CARDS_REPORTING_SUCCESS,
  (response) => response
);
export const getOrderCardReportingError = createAction(
  ActionTypes.GET_ORDER_CARDS_REPORTING_ERROR,
  (error) => error
);

export const getOrderCardReporting = (callback) => async (dispatch) => {
  try {
    dispatch(getOrderCardReportingStart());
    const response = await _getOrderCardReporting();
    dispatch(getOrderCardReportingSuccess(response));
    if (response) {
      callback(response);
    }
  } catch (error) {
    dispatch(getOrderCardReportingError(error));
    if (error?.status?.length > 0) {
      toast.error(error?.message);
    } else {
      toast.error("Something went wrong");
    }
  }
};

export const orderReportingStart = createAction(
  ActionTypes.GET_ORDER_REPORTING_START
);
export const orderReportingSuccess = createAction(
  ActionTypes.GET_ORDER_REPORTING_SUCCESS,
  (response) => response
);
export const orderReportingError = createAction(
  ActionTypes.GET_ORDER_REPORTING_ERROR,
  (error) => error
);

export const getOrderReporting =
  (fromDate, toDate, callback) => async (dispatch) => {
    try {
      dispatch(orderReportingStart());
      const response = await _orderReporting(fromDate, toDate);
      dispatch(orderReportingSuccess(response));
      if (response) {
        callback(response);
      }
    } catch (error) {
      dispatch(orderReportingError(error));
      if (error?.status?.length > 0) {
        toast.error(error?.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

export const monthlySaleReportStart = createAction(
  ActionTypes.GET_MONTHLY_SALE_REPORT_START
);
export const monthlySaleReportSuccess = createAction(
  ActionTypes.GET_MONTHLY_SALE_REPORT_SUCCESS,
  (response) => response
);
export const monthlySaleReportError = createAction(
  ActionTypes.GET_MONTHLY_SALE_REPORT_ERROR,
  (error) => error
);

export const getMonthlySaleReport =
  (selectedMonths, callback) => async (dispatch) => {
    try {
      dispatch(monthlySaleReportStart());
      const response = await _monthlySaleReport(selectedMonths);
      dispatch(monthlySaleReportSuccess(response));
      if (response) {
        callback(response);
      }
    } catch (error) {
      dispatch(monthlySaleReportError(error));
      if (error?.status?.length > 0) {
        toast.error(error?.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

export const topSellingProductsStart = createAction(
  ActionTypes.TOP_SELLING_PRODUCTS_START
);
export const topSellingProductsSuccess = createAction(
  ActionTypes.TOP_SELLING_PRODUCTS_SUCCESS,
  (response) => response
);
export const topSellingProductsError = createAction(
  ActionTypes.TOP_SELLING_PRODUCTS_ERROR,
  (error) => error
);

export const getTopSellingProducts =
  (limit, fromDate, toDate, callback) => async (dispatch) => {
    try {
      dispatch(topSellingProductsStart());
      const response = await _topSellingProducts(limit, fromDate, toDate);
      dispatch(topSellingProductsSuccess(response));
      if (response) {
        callback(response);
      }
    } catch (error) {
      dispatch(topSellingProductsError(error));
      if (error?.status?.length > 0) {
        toast.error(error?.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };
const reducer = handleActions(
  {
    [ActionTypes.GET_ADMIN_PHARMACIES]: (state) => ({
      ...state,
      pharmacies: {
        ...state.pharmacies,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_ADMIN_PHARMACIES_SUCCESS]: (state, action) => ({
      ...state,

      pharmacies: {
        ...state.pharmacies,
        loading: false,
        hasError: false,
        error: {},
        response: action.payload?.data,
      },
    }),
    [ActionTypes.GET_ADMIN_PHARMACIES_ERROR]: (state) => ({
      ...state,

      pharmacies: {
        ...state.pharmacies,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),

    [ActionTypes.GET_ADMIN_QUERIES]: (state) => ({
      ...state,
      queries: {
        ...state.queries,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_ADMIN_QUERIES_SUCCESS]: (state, action) => ({
      ...state,

      queries: {
        ...state.queries,
        loading: false,
        hasError: false,
        error: {},
        response: action.payload?.data,
      },
    }),
    [ActionTypes.GET_ADMIN_QUERIES_ERROR]: (state) => ({
      ...state,

      queries: {
        ...state.queries,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),

    [ActionTypes.GET_STATE_TAXES_START]: (state) => ({
      ...state,
      stateTaxes: {
        ...state.stateTaxes,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_STATE_TAXES_SUCCESS]: (state, action) => ({
      ...state,
      stateTaxes: {
        ...state.stateTaxes,
        loading: false,
        hasError: false,
        error: {},
        response: action.payload?.data,
      },
    }),
    [ActionTypes.GET_STATE_TAXES_ERROR]: (state) => ({
      ...state,
      stateTaxes: {
        ...state.stateTaxes,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),

    [ActionTypes.GET_PARAMETERS_START]: (state) => ({
      ...state,
      parameters: {
        ...state.parameters,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_PARAMETERS_SUCCESS]: (state, action) => ({
      ...state,
      parameters: {
        ...state.parameters,
        loading: false,
        hasError: false,
        error: {},
        response: action.payload?.data,
      },
    }),
    [ActionTypes.GET_PARAMETERS_ERROR]: (state) => ({
      ...state,
      parameters: {
        ...state.parameters,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),

    [ActionTypes.GET_DRIVERS_START]: (state) => ({
      ...state,
      drivers: {
        ...state.drivers,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_DRIVERS_SUCCESS]: (state, action) => ({
      ...state,
      drivers: {
        ...state.drivers,
        loading: false,
        hasError: false,
        error: {},
        response: action.payload?.data,
      },
    }),
    [ActionTypes.GET_DRIVERS_ERROR]: (state) => ({
      ...state,
      drivers: {
        ...state.drivers,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),

    [ActionTypes.GET_CITY_PRICES_START]: (state) => ({
      ...state,
      cityPrices: {
        ...state.cityPrices,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_CITY_PRICES_SUCCESS]: (state, action) => ({
      ...state,
      cityPrices: {
        ...state.cityPrices,
        loading: false,
        hasError: false,
        error: {},
        response: action.payload?.data,
      },
    }),
    [ActionTypes.GET_CITY_PRICES_ERROR]: (state) => ({
      ...state,
      cityPrices: {
        ...state.cityPrices,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),

    [ActionTypes.GET_ADMIN_PHARMACY]: (state) => ({
      ...state,
      pharmacy: {
        ...state.pharmacy,
        loading: true,
        hasError: false,
        error: {},
        response: {},
      },
    }),
    [ActionTypes.GET_ADMIN_PHARMACY_SUCCESS]: (state, action) => ({
      ...state,

      pharmacy: {
        ...state.pharmacy,
        loading: false,
        hasError: false,
        error: {},
        response: action.payload?.data,
      },
    }),
    [ActionTypes.GET_ADMIN_PHARMACY_ERROR]: (state) => ({
      ...state,

      updatePharmacyStatus: {
        ...state.updatePharmacyStatus,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),

    [ActionTypes.GET_ADMIN_QUERIES_DETAIL]: (state) => ({
      ...state,
      queriesDetail: {
        ...state.queriesDetail,
        loading: true,
        hasError: false,
        error: {},
        response: {},
      },
    }),
    [ActionTypes.GET_ADMIN_QUERIES_DETAIL_SUCCESS]: (state, action) => ({
      ...state,
      queriesDetail: {
        ...state.queriesDetail,
        loading: false,
        hasError: false,
        error: {},
        response: action.payload?.data,
      },
    }),
    [ActionTypes.GET_ADMIN_QUERIES_DETAIL_ERROR]: (state) => ({
      ...state,
      queriesDetail: {
        ...state.queriesDetail,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),

    [ActionTypes.UPDATE_PHARMACY_STATUS_ADMIN_PHARMACY]: (state) => ({
      ...state,
      updatePharmacyStatus: {
        ...state.updatePharmacyStatus,
        loading: true,
        hasError: false,
        error: {},
        response: {},
      },
    }),
    [ActionTypes.UPDATE_PHARMACY_STATUS_SUCCESS]: (state, action) => ({
      ...state,

      updatePharmacyStatus: {
        ...state.updatePharmacyStatus,
        loading: false,
        hasError: false,
        error: {},
        response: action.payload?.data,
      },
    }),
    [ActionTypes.UPDATE_PHARMACY_STATUS_ERROR]: (state) => ({
      ...state,

      updatePharmacyStatus: {
        ...state.updatePharmacyStatus,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),

    [ActionTypes.SEND_REUPLOAD_LINK_START]: (state) => ({
      ...state,
      reUploadDocs: {
        ...state.reUploadDocs,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.SEND_REUPLOAD_LINK_SUCCESS]: (state, action) => ({
      ...state,
      reUploadDocs: {
        ...state.reUploadDocs,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),
    [ActionTypes.SEND_REUPLOAD_LINK_ERROR]: (state, action) => ({
      ...state,
      reUploadDocs: {
        ...state.reUploadDocs,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),
    [ActionTypes.GET_ORDER_REPORTING_START]: (state) => ({
      ...state,
      orderReporting: {
        ...state.orderReporting,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_ORDER_REPORTING_SUCCESS]: (state, action) => ({
      ...state,
      orderReporting: {
        ...state.orderReporting,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_ORDER_REPORTING_ERROR]: (state, action) => ({
      ...state,
      orderReporting: {
        ...state.orderReporting,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),

    [ActionTypes.GET_ORDER_CARDS_REPORTING_START]: (state) => ({
      ...state,
      orderCardsReporting: {
        ...state.orderCardsReporting,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_ORDER_CARDS_REPORTING_SUCCESS]: (state, action) => ({
      ...state,
      orderCardsReporting: {
        ...state.orderCardsReporting,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_ORDER_CARDS_REPORTING_ERROR]: (state, action) => ({
      ...state,
      orderCardsReporting: {
        ...state.orderCardsReporting,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),
    [ActionTypes.GET_MONTHLY_SALE_REPORT_START]: (state) => ({
      ...state,
      monthlySaleReport: {
        ...state.monthlySaleReport,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_MONTHLY_SALE_REPORT_SUCCESS]: (state, action) => ({
      ...state,
      monthlySaleReport: {
        ...state.monthlySaleReport,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_MONTHLY_SALE_REPORT_ERROR]: (state, action) => ({
      ...state,
      monthlySaleReport: {
        ...state.monthlySaleReport,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),

    [ActionTypes.TOP_SELLING_PRODUCTS_START]: (state) => ({
      ...state,
      topSellingProducts: {
        ...state.topSellingProducts,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.TOP_SELLING_PRODUCTS_SUCCESS]: (state, action) => ({
      ...state,
      topSellingProducts: {
        ...state.topSellingProducts,
        response: action.payload,
        loading: false,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.TOP_SELLING_PRODUCTS_ERROR]: (state, action) => ({
      ...state,
      topSellingProducts: {
        ...state.topSellingProducts,
        error: action.payload,
        loading: false,
        hasError: true,
        response: {},
      },
    }),

    ["SET_PHARMACY_ID"]: (state, action) => ({
      ...state,

      pharmacyId: action.payload,
    }),
  },
  initialState
);

export default reducer;
