import React from "react";
import "./authLayout.scss";
import { Box, Grid, Typography } from "@mui/material";
import medicine from "../../../assets/images/medicine.png";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router";

const AuthLayout = ({ children }) => {
  const history = useNavigate();
  return (
    <>
      
      <Grid
        container
        sx={{ justifyContent: "center", height: "100%", alignItems: "center" }}
      >
        <Grid
          container
          maxWidth="xl"
          alignItems="center"
          justifyContent="center"
        >
          <Box px={{xs:3,sm:3,md:3}} flex={1} className="signUpcontainer">
            {children}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AuthLayout;
