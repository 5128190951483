import React, { lazy, useState, useEffect, Suspense, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import pages from "../nav";
import pagesForFilter from "../filteredNav";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import Layout from "../shared/components/layout/container";

const importView = (file) =>
  lazy(() =>
    import(`./${file}/index.js`).catch((err) =>
      console.log(`Error in importing ${err}`)
    )
  );

export default function Index() {
  const { allowedPages, loading_user } = useContext(AuthContext);
  const history = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { view } = useParams();
  const location = useLocation();
  const metaViewData = pages;
  const [selectedView, setSelectedView] = useState([]);

  async function loadView(filtered) {
    const promise = filtered.map(async (_view) => {
      const View = await importView(_view.file);

      return <View key={_view.id} selectView={selectView} />;
    });
    Promise.all(promise).then(setSelectedView);
  }

  async function selectView(file) {
    const filtered = pagesForFilter?.filter((elem) => elem.file === file);

    loadView([filtered[0]]);
  }

  useEffect(() => {
    let fileToLoad = view;
    if (!allowedPages.includes(fileToLoad)) {
      fileToLoad = "dashboard";
    }
    if (fileToLoad === "null" || fileToLoad === "dash/null") {
      fileToLoad = "dashboard";
    }

    if (allowedPages.length === 1 && allowedPages[0] === "no-permissions") {
      fileToLoad = "no-permissions";
    }

    if (allowedPages.includes(fileToLoad)) {
      fileToLoad = allowedPages[allowedPages.indexOf(fileToLoad)];
    }
    if (
      allowedPages.includes(fileToLoad) &&
      !metaViewData.find((elem) => elem.file === view) &&
      !metaViewData.some((elem) => {
        if (elem?.children) {
          return elem.children.some((child) => child?.file === fileToLoad);
        }
        return false;
      })
    ) {
      fileToLoad = "dashboard";
    }
    history({
      pathname: `/dash/${fileToLoad}`,
      search: location?.search ? location?.search : "",
    });
    selectView(fileToLoad);
  }, [view, allowedPages, loading_user]);

  return loading_user ? (
    <Box
      sx={{
        width: "100%",
        height: "20vh",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <CircularProgress sx={{ color: "#235D5E" }} />
    </Box>
  ) : (
    <Layout title={view} showLayout={true}>
      <Suspense
      // fallback={
      //   <Box
      //     sx={{
      //       width: "100%",
      //       height: "20vh",
      //       alignItems: "center",
      //       textAlign: "center",
      //     }}
      //   >
      //     <CircularProgress sx={{ color: "#235D5E" }} />
      //   </Box>
      // }
      >
        {selectedView}
      </Suspense>
    </Layout>
  );
}
