export default {
  GET_STARSHIP_DETAILS: "GET_STARSHIP_DETAILS",
  GET_STARSHIP_DETAILS_SUCCESS: "GET_STARSHIP_DETAILS_SUCCESS",
  GET_STARSHIP_DETAILS_ERROR: "GET_STARSHIP_DETAILS_ERROR",

  SIGNIN: "SIGNIN",
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  SIGNIN_ERROR: "SIGNIN_ERROR",

  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",

  CREATE_NEW_PASSWORD: "CREATE_NEW_PASSWORD",
  CREATE_NEW_PASSWORD_SUCCESS: "CREATE_NEW_PASSWORD_SUCCESS",
  CREATE_NEW_PASSWORD_ERROR: "CREATE_NEW_PASSWORD_ERROR",

  GET_ADMIN_PHARMACIES: "GET_ADMIN_PHARMACIES",
  GET_ADMIN_PHARMACIES_SUCCESS: "GET_ADMIN_PHARMACIES_SUCCESS",
  GET_ADMIN_PHARMACIES_ERROR: "GET_ADMIN_PHARMACIES_ERROR",

  GET_ADMIN_QUERIES: "GET_ADMIN_QUERIES",
  GET_ADMIN_QUERIES_SUCCESS: "GET_ADMIN_QUERIES_SUCCESS",
  GET_ADMIN_QUERIES_ERROR: "GET_ADMIN_QUERIES_ERROR",

  GET_PARAMETERS_START: "GET_PARAMETERS_START",
  GET_PARAMETERS_SUCCESS: "GET_PARAMETERS_SUCCESS",
  GET_PARAMETERS_ERROR: "GET_PARAMETERS_ERROR",

  GET_DRIVERS_START: "GET_DRIVERS_START",
  GET_DRIVERS_SUCCESS: "GET_DRIVERS_SUCCESS",
  GET_DRIVERS_ERROR: "GET_DRIVERS_ERROR",

  GET_STATE_TAXES_START: "GET_STATE_TAXES_START",
  GET_STATE_TAXES_SUCCESS: "GET_STATE_TAXES_SUCCESS",
  GET_STATE_TAXES_ERROR: "GET_STATE_TAXES_ERROR",

  GET_CITY_PRICES_START: "GET_CITY_PRICES_START",
  GET_CITY_PRICES_SUCCESS: "GET_CITY_PRICES_SUCCESS",
  GET_CITY_PRICES_ERROR: "GET_CITY_PRICES_ERROR",

  GET_ADMIN_PHARMACY: "GET_ADMIN_PHARMACY",
  GET_ADMIN_PHARMACY_SUCCESS: "GET_ADMIN_PHARMACY_SUCCESS",
  GET_ADMIN_PHARMACY_ERROR: "GET_ADMIN_PHARMACY_ERROR",

  GET_ADMIN_QUERIES_DETAIL: "GET_ADMIN_QUERIES_DETAIL",
  GET_ADMIN_QUERIES_DETAIL_SUCCESS: "GET_ADMIN_QUERIES_DETAIL_SUCCESS",
  GET_ADMIN_QUERIES_DETAIL_ERROR: "GET_ADMIN_QUERIES_DETAIL_ERROR",

  UPDATE_PHARMACY_STATUS_ADMIN_PHARMACY:
    "UPDATE_PHARMACY_STATUS_ADMIN_PHARMACY",
  UPDATE_PHARMACY_STATUS_SUCCESS: "UPDATE_PHARMACY_STATUS_SUCCESS",
  UPDATE_PHARMACY_STATUS_ERROR: "UPDATE_PHARMACY_STATUS_ERROR",

  GET_ADMIN_NOTIFICATIONS: "GET_ADMIN_NOTIFICATIONS",
  GET_ADMIN_NOTIFICATIONS_SUCCESS: "GET_ADMIN_NOTIFICATIONS_SUCCESS",
  GET_ADMIN_NOTIFICATIONS_ERROR: "GET_ADMIN_NOTIFICATIONS_ERROR",

  UPDATE_ADMIN_NOTIFICATIONS: "UPDATE_ADMIN_NOTIFICATIONS",
  UPDATE_ADMIN_NOTIFICATIONS_SUCCESS: "UPDATE_ADMIN_NOTIFICATIONS_SUCCESS",
  UPDATE_ADMIN_NOTIFICATIONS_ERROR: "UPDATE_ADMIN_NOTIFICATIONS_ERROR",

  RESEND_QR_DETAILS_START: "RESEND_QR_DETAILS_START",
  RESEND_QR_DETAILS_SUCCESS: "RESEND_QR_DETAILS_SUCCESS",
  RESEND_QR_DETAILS_ERROR: "RESEND_QR_DETAILS_ERROR",

  SEND_REUPLOAD_LINK_START: "SEND_REUPLOAD_LINK_START",
  SEND_REUPLOAD_LINK_SUCCESS: "SEND_REUPLOAD_LINK_SUCCESS",
  SEND_REUPLOAD_LINK_ERROR: "SEND_REUPLOAD_LINK_ERROR",

  GET_ADMIN_ORDERS: "GET_ADMIN_ORDERS",
  GET_ADMIN_ORDERS_SUCCESS: "GET_ADMIN_ORDERS_SUCCESS",
  GET_ADMIN_ORDERS_ERROR: "GET_ADMIN_ORDERS_ERROR",

  GET_BLOGS_START: "GET_BLOGS_START",
  GET_BLOGS_SUCCESS: "GET_BLOGS_SUCCESS",
  GET_BLOGS_ERROR: "GET_BLOGS_ERROR",

  GET_BLOGS_DETAIL_START: "GET_BLOGS_DETAIL_START",
  GET_BLOGS_DETAIL_SUCCESS: "GET_BLOGS_DETAIL_SUCCESS",
  GET_BLOGS_DETAIL_ERROR: "GET_BLOGS_DETAIL_ERROR",

  GET_ADMIN_ORDER_DETAIL_START: "GET_ADMIN_ORDER_DETAIL_START",
  GET_ADMIN_ORDER_DETAIL_SUCCESS: "GET_ADMIN_ORDER_DETAIL_SUCCESS",
  GET_ADMIN_ORDER_DETAIL_ERROR: "GET_ADMIN_ORDER_DETAIL_ERROR",

  UPDATE_ADMIN_ORDER_STATUS: "UPDATE_ADMIN_ORDER_STATUS",
  UPDATE_ADMIN_ORDER_STATUS_SUCCESS: "UPDATE_ADMIN_ORDER_STATUS_SUCCESS",
  UPDATE_ADMIN_ORDER_STATUS_ERROR: "UPDATE_ADMIN_ORDER_STATUS_ERROR",

  UPDATE_BLOG_START: "UPDATE_BLOG_START",
  UPDATE_BLOG_SUCCESS: "UPDATE_BLOG_SUCCESS",
  UPDATE_BLOG_ERROR: "UPDATE_BLOG_ERROR",

  DELETE_BLOG_START: "DELETE_BLOG_START",
  DELETE_BLOG_SUCCESS: "DELETE_BLOG_SUCCESS",
  DELETE_BLOG_ERROR: "DELETE_BLOG_ERROR",

  ADD_BLOG_START: "ADD_BLOG_START",
  ADD_BLOG_SUCCESS: "ADD_BLOG_SUCCESS",
  ADD_BLOG_ERROR: "ADD_BLOG_ERROR",

  SCAN_QR_START: "SCAN_QR_START",
  SCAN_QR_SUCCESS: "SCAN_QR_SUCCESS",
  SCAN_QR_ERROR: "SCAN_QR_ERROR",

  GET_ADMIN_BUSINESSES: "GET_ADMIN_BUSINESSES",
  GET_ADMIN_BUSINESSES_SUCCESS: "GET_ADMIN_BUSINESSES_SUCCESS",
  GET_ADMIN_BUSINESSES_ERROR: "GET_ADMIN_BUSINESSES_ERROR",

  GET_ADMIN_BUSINESS: "GET_ADMIN_BUSINESS",
  GET_ADMIN_BUSINESS_SUCCESS: "GET_ADMIN_BUSINESS_SUCCESS",
  GET_ADMIN_BUSINESS_ERROR: "GET_ADMIN_BUSINESS_ERROR",

  UPDATE_BUSINESS_STATUS_ADMIN: "UPDATE_BUSINESS_STATUS_ADMIN",
  UPDATE_BUSINESS_STATUS_SUCCESS: "UPDATE_BUSINESS_STATUS_SUCCESS",
  UPDATE_BUSINESS_STATUS_ERROR: "UPDATE_BUSINESS_STATUS_ERROR",

  GET_ADMIN_MEMBERS: "GET_ADMIN_MEMBERS",
  GET_ADMIN_MEMBERS_SUCCESS: "GET_ADMIN_MEMBERS_SUCCESS",
  GET_ADMIN_MEMBERS_ERROR: "GET_ADMIN_MEMBERS_ERROR",

  GET_ADMIN_MEMBER: "GET_ADMIN_MEMBER",
  GET_ADMIN_MEMBER_SUCCESS: "GET_ADMIN_MEMBER_SUCCESS",
  GET_ADMIN_MEMBER_ERROR: "GET_ADMIN_MEMBER_ERROR",

  UPDATE_MEMBER_STATUS_ADMIN: "UPDATE_MEMBER_STATUS_ADMIN",
  UPDATE_MEMBER_STATUS_SUCCESS: "UPDATE_MEMBER_STATUS_SUCCESS",
  UPDATE_MEMBER_STATUS_ERROR: "UPDATE_MEMBER_STATUS_ERROR",

  UPDATE_MEMBER_STATUS_ADMIN_SUSPEND: "UPDATE_MEMBER_STATUS_ADMIN_SUSPEND",
  UPDATE_MEMBER_STATUS_SUCCESS_SUSPEND: "UPDATE_MEMBER_STATUS_SUCCESS_SUSPEND",
  UPDATE_MEMBER_STATUS_ERROR_SUSPEND: "UPDATE_MEMBER_STATUS_ERROR_SUSPEND",

  GET_ORDER_REPORTING_START: "GET_ORDER_REPORTING_START",
  GET_ORDER_REPORTING_SUCCESS: "GET_ORDER_REPORTING_SUCCESS",
  GET_ORDER_REPORTING_ERROR: "GET_ORDER_REPORTING_ERROR",

  GET_ORDER_CARDS_REPORTING_START: "GET_ORDER_CARDS_REPORTING_START",
  GET_ORDER_CARDS_REPORTING_SUCCESS: "GET_ORDER_CARDS_REPORTING_SUCCESS",
  GET_ORDER_CARDS_REPORTING_ERROR: "GET_ORDER_CARDS_REPORTING_ERROR",

  TOP_SELLING_PRODUCTS_START: "TOP_SELLING_PRODUCTS_START",
  TOP_SELLING_PRODUCTS_SUCCESS: "TOP_SELLING_PRODUCTS_SUCCESS",
  TOP_SELLING_PRODUCTS_ERROR: "TOP_SELLING_PRODUCTS_ERROR",

  GET_MONTHLY_SALE_REPORT_START: "GET_MONTHLY_SALE_REPORT_START",
  GET_MONTHLY_SALE_REPORT_SUCCESS: "GET_MONTHLY_SALE_REPORT_SUCCESS",
  GET_MONTHLY_SALE_REPORT_ERROR: "GET_MONTHLY_SALE_REPORT_ERROR",

  GET_PRODUCTS_START: "GET_PRODUCTS_START",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_ERROR: "GET_PRODUCTS_ERROR",

  GET_PRODUCT_DETAIL_START: "GET_PRODUCT_DETAIL_START",
  GET_PRODUCT_DETAIL_SUCCESS: "GET_PRODUCT_DETAIL_SUCCESS",
  GET_PRODUCT_DETAIL_ERROR: "GET_PRODUCT_DETAIL_ERROR",

  GET_PRODUCT_INVENTORY_START: "GET_PRODUCT_INVENTORY_START",
  GET_PRODUCT_INVENTORY_SUCCESS: "GET_PRODUCT_INVENTORY_SUCCESS",
  GET_PRODUCT_INVENTORY_ERROR: "GET_PRODUCT_INVENTORY_ERROR",

  GET_STORES_START: "GET_STORES_START",
  GET_STORES_SUCCESS: "GET_STORES_SUCCESS",
  GET_STORES_ERROR: "GET_STORES_ERROR",

  GET_ACTIVE_BUSINESS_START: "GET_ACTIVE_BUSINESS_START",
  GET_ACTIVE_BUSINESS_SUCCESS: "GET_ACTIVE_BUSINESS_SUCCESS",
  GET_ACTIVE_BUSINESS_ERROR: "GET_ACTIVE_BUSINESS_ERROR",

  ADD_SOCKETS_SUCCESS: "ADD_SOCKETS_SUCCESS",
};
