import { useContext, useEffect, useMemo, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { AuthContext } from "../../../context/authContext";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Box from "@mui/material/Box";
import "./sidebar.scss";
import { ListItemIcon, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import dashboardIcon from "../../../assets/images/dahsboard.svg";
import {
  connectionWithSocketServer,
  socketServer,
} from "../../../realtimeCommunication/socketConnection";
import userProfile from "../../../assets/images/userProfile.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import nxusLogo from "../../../assets/images/connectRx/connectrxLogo.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import sideNavData from "../../../nav";

const drawerWidth = 240;

export const Sidebar = () => {
  const location = useLocation();
  const history = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { allowedPages } = useContext(AuthContext);

  const sideBarItems = useMemo(
    () =>
      sideNavData
        .filter(({ file, menubar }) => allowedPages.includes(file) && menubar)
        .map((item, index) => item),
    [sideNavData, allowedPages]
  );

  const isActive = (value, link_for) => {
    if (
      location?.pathname?.includes(value) ||
      location?.pathname?.includes(link_for)
    ) {
      return "active";
    }

    return "";
  };

  const renderChildItems = (children) => {
    return children
      .filter((childItem) => allowedPages?.includes(childItem?.file))
      .map((childItem) => (
        <ListItem
          sx={{ padding: "0px 0px" }}
          key={childItem?.file}
          disablePadding
          className={isActive(`${childItem?.link_to}`, childItem?.link_for)}
          onClick={() => history(`${childItem?.link_to}`, { replace: true })}
        >
          <ListItemButton sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <ListItemIcon sx={{ minWidth: "30px" }}>
              {childItem?.icon}
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="17px">{childItem?.name}</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      ));
  };
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        zIndex: "1201",
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
        // background:"linear-gradient()"
      }}
    >
      <Box className="headerLogoContainer">
        <img src={nxusLogo} alt="logo" />
        {/* <Typography color="#ffff !important" className="logoText">
          UnioRx
        </Typography> */}
      </Box>
      <Box sx={{ overflow: "auto" }}>
        <List>
          {sideBarItems && sideBarItems.length > 0 ? (
            sideBarItems.map((item, index) => (
              <div key={index}>
                {item?.children && item?.children?.length > 0 ? (
                  <Box sx={{ padding: "0px 0px 0px 0px" }}>
                    <Accordion className="accord_main">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accord_summary"
                      >
                        <Typography className="accord_title">
                          {item?.name}
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails className="accord_details">
                        {renderChildItems(item?.children)}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                ) : (
                  <>
                    <ListItem
                      sx={{ padding: "14px 8px" }}
                      disablePadding
                      className={isActive(`${item?.link_to}`, item?.link_for)}
                      onClick={() =>
                        history(`${item?.link_to}`, { replace: true })
                      }
                    >
                      <ListItemButton
                        sx={{ paddingTop: "0px", paddingBottom: "0px" }}
                      >
                        <ListItemIcon sx={{ minWidth: "30px" }}>
                          {item?.icon}
                        </ListItemIcon>
                        <ListItemText>
                          <Typography fontSize="17px">{item?.name}</Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </>
                )}
              </div>
            ))
          ) : (
            <>
              {allowedPages &&
              allowedPages?.length > 0 &&
              allowedPages[0] != "no-pemissions" ? (
                <ListItem
                  disablePadding
                  className={isActive("/dash/dashboard")}
                  onClick={() => history("/dash/dashboard", { replace: true })}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <DashboardIcon style={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText>Dashboard</ListItemText>
                  </ListItemButton>
                </ListItem>
              ) : (
                ""
              )}
            </>
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
