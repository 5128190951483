import React, { useEffect, Suspense } from "react";
import Footer from "../../../../shared/components/footer";
import Header from "../../../../shared/components/header";
import Sidebar from "../../../../shared/components/sidebar";
import Box from "@mui/material/Box";
import { updateSession } from "../../../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

export const ProtectedLayout = ({ showLayout, children }) => {
  const dispatch = useDispatch();
  const { user, isSessionExpired } = useSelector((state) => state?.auth);
  let timer;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, 100);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  const logoutAction = () => {
    if (!user?.email) {
      dispatch(updateSession(true));
    }
  };

  return (
    <>
      {showLayout && (
        <Box>
          <Header />
          <Box sx={{ display: "flex" }}>
            <Sidebar />
            <Box component="main" sx={{ flexGrow: 1, padding: "6rem 2rem" }}>
              {children}
            </Box>
          </Box>
          <Footer />
        </Box>
      )}
    </>
  );
};

export default ProtectedLayout;
