import { createContext, useEffect, useState, useContext } from "react";
import {
  connectionWithSocketServer,
  socketServer,
  forcefullyDisconnect,
} from "../realtimeCommunication/socketConnection";
import { addSocket } from "../services/auth";
import { AuthContext } from "../context/authContext";

import { useDispatch, useSelector } from "react-redux";

const context = {};

export const SocketContext = createContext(context);

export function SocketContextProvider(props) {
  const { hasPermission, allowedPages } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { user, sockets } = useSelector((state) => state?.auth);
  const [userSocket, setUserSocketData] = useState(null);
  const [userSocketId, setUserSocketId] = useState("");

  useEffect(() => {
    dispatch(addSocket([]));
  }, []);

  useEffect(() => {
    if (user && user?.email) {
      if (socketServer()) {
        socketServer().emit("forceDisconnect", sockets);
        dispatch(addSocket([]));
      }

      setTimeout(() => {
        (async () => {
          let newSocketData = await connectionWithSocketServer(
            user,
            function (sockID) {
              setUserSocketId(sockID);
            }
          );

          if (newSocketData) {
            setUserSocketData(newSocketData);
          }
        })();
      }, 1000);
    }
    return () => {
      if (socketServer()) {
        socketServer().off("connect");
        socketServer().off("disconnect");
        socketServer().off();
      }
    };
  }, [user]);

  useEffect(() => {
    if (!user?.email && socketServer()) {
      if (userSocketId) {
        forcefullyDisconnect(userSocket, [userSocketId], function (res) {
          if (res) {
            setUserSocketId("");
            setUserSocketData(null);
          }
        });
      }
    }
  }, [userSocketId?.length]);

  useEffect(() => {
    if (
      hasPermission("users.recieveNotifications") &&
      userSocket &&
      user?.email
    ) {
      userSocket.emit("join-admins-room", {});
    }
  }, [allowedPages, userSocket, sockets?.length]);

  return <SocketContext.Provider>{props.children}</SocketContext.Provider>;
}
