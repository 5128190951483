import CryptoJS from "crypto-js";
export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/`;

  return true;
};

export const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const clearCookie = (name) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

  return true;
};

export const decryptPermissions = (
  encryptedPayload,
  encryptionKey,
  toast = null,
  logout = null
) => {
  if (!encryptedPayload) return;
  let decryptedData = [];
  try {
    let bytes = CryptoJS.AES.decrypt(encryptedPayload, encryptionKey);
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    if (toast) {
      toast.error("Permission tempered!!!");

      logout();
    }
  }

  console.log("===================>", decryptedData);
  return decryptedData;
};

// Function to perform encryption on the frontend
export const encryptPermissions = (permissions, encryptionKey) => {
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(permissions),
    encryptionKey
  ).toString();

  return ciphertext;
};

export const groupedPermissions = (permissions) => {
  const groupedPermissions = permissions.reduce((grouped, permission) => {
    let { route } = permission;

    route = route?.split("/")[1];
    if (!grouped[route]) {
      grouped[route] = [];
    }
    grouped[route].push(permission);
    return grouped;
  }, {});
  return groupedPermissions;
};

export const generateRandomPassword = () => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}|[];:<>,.?/~";
  const passwordLength = 10;
  let newPassword = "";
  for (let i = 0; i < passwordLength; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    newPassword += charset[randomIndex];
  }
  return newPassword;
};
