/* eslint-disable no-unreachable */
import { useState, createContext, useEffect, useMemo } from "react";
import { useLocation, matchPath, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  clearCookie,
  getCookie,
  setCookie,
  decryptPermissions,
  encryptPermissions,
} from "../helpers/common";
import API from "../shared/httpService/config";
import URLS from "../shared/constants/urls";
import { logout } from "../services/auth";
import { useSelector, useDispatch } from "react-redux";

import { resetStore } from "../services/combinedAuth";
const context = {};

export const AuthContext = createContext(context);

export function AuthContextProvider(props) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { user } = useSelector((state) => state?.auth);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [permissions, setPermissions] = useState([]);
  const [loading_user, setLoadingUser] = useState(false);
  const [allowedPages, setAllowedPages] = useState(
    getCookie("allowed_pages")
      ? decryptPermissions(
          JSON.parse(getCookie("allowed_pages")),
          "MOABDA",
          toast,
          onPermissionTempered
        )
      : []
  );

  const getPermissions = () => {
    setLoadingUser(true);

    API.get(
      `${process?.env?.REACT_APP_BASE_URL}/api/v1/admin/permissions/current-user`
    )
      .then((response) => {
        let { permissions } = response.data;
        let decryptedPermissions = [];

        let navPermissions = [];
        if (permissions && permissions?.length) {
          decryptedPermissions = decryptPermissions(
            permissions,
            "MOABDA",
            toast,
            onPermissionTempered
          );
        }
        if (permissions && decryptedPermissions?.length) {
          navPermissions = decryptedPermissions
            .filter((p) => p?.includes(".nav"))
            ?.map((p) => p?.split(".")[0]);
        }

        setAllowedPages([...navPermissions]);

        setCookie(
          "allowed_pages",
          JSON.stringify(encryptPermissions(navPermissions, "MOABDA"))
        );

        setPermissions(decryptedPermissions);
      })
      .catch((err) => {
        setCookie(
          "allowed_pages",
          JSON.stringify(encryptPermissions(["no-permissions"], "MOABDA"))
        );
        setAllowedPages(["no-permissions"]);
        setLoadingUser(false);
        toast(err?.message);
        setPermissions([]);
      });
  };

  useEffect(() => {
    if (user && user?.token && user?.email) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [user]);

  useEffect(() => {
    if (isLoggedIn) {
      getPermissions();
    }
    window.addEventListener("FETCH_ADMIN_ROLE", () => {
      getPermissions();
    });
    return () => {
      window.removeEventListener("FETCH_ADMIN_ROLE", () => {
        getPermissions();
      });
    };
  }, [isLoggedIn]);

  function onPermissionTempered() {
    let action = "USER_LOGOUT";
    dispatch(logout(function () {}));
    dispatch(resetStore(action));
    // setAllowedPages([]);
    setLoadingUser(false);
    setIsLoggedIn(false);

    history("/login", { replace: true });
  }
  const onLogout = async () => {
    setAllowedPages([]);
    setLoadingUser(false);
    setIsLoggedIn(false);
  };

  useEffect(() => {
    const storageListener = (e) => {
      if (e.key === "token" && e.oldValue && !e.newValue) {
        let action = "USER_LOGOUT";
        dispatch(resetStore(action));
        onLogout();

        history("/login", { replace: true });
      }
    };

    window.addEventListener("storage", storageListener);

    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  const hasPermission = (perm) => permissions.includes(perm);

  const allContext = useMemo(
    () => ({
      setIsLoggedIn,
      hasPermission,
      allowedPages,
      isLoggedIn,
      setAllowedPages,
      user,
      onLogout,
    }),
    [isLoggedIn, user, hasPermission, allowedPages, loading_user]
  );

  return (
    <AuthContext.Provider value={allContext}>
      <>{props?.children}</>
    </AuthContext.Provider>
  );
}
